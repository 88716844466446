.main__third-services {
  padding-top: 15px;
}
.main__gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-bottom: 20px;
}
.main__gallery-img {
  height: 300px;
}
.main-title-gallery {
  margin: 10px auto 45px auto;
  border-radius: 10px;
  max-width: 600px;
  background-color: rgb(7, 89, 156);
  padding: 15px;
  text-align: center;
  color: white;
}
.main__gallery-youtube-img {
  opacity: 0.8;
  transition-duration: 0.6s;
}
.main__gallery-youtube-img:hover {
  opacity: 1;
}
.main__gallery-item {
  border-radius: 5px;
  padding: 15px;
  background-color: rgb(255, 255, 255);
  text-align: center;
}
.main__gallery-item h2 {
  height: 50px;
}
.main__gallery-item:hover {
  box-shadow: inset 3px 3px 3px rgb(85, 210, 219),
    inset -3px -3px 3px rgb(85, 210, 219);
}

@media (max-width: 800px) {
  .main__gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 20px;
  }
}
@media (max-width: 600px) {
  .main__gallery {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin-bottom: 20px;
  }
  .main__gallery-item {
    max-width: 350px;
    margin: 0 auto;
  }
  .main__gallery-item h2 {
    font-size: 20px;
  }
}
