.linkforma {
  scale: 0.7;
  position: fixed;
  bottom: 120px;
  right: 30px;
  opacity: 0.8;
  transition-duration: 0.7s;
  animation: rotate-animation 1s infinite;
  z-index: 100;
  transition: 0.8s;
}
.linkforma:hover {
  animation: none;
  transform: scale(1.1);
  transition: 0.8s;
}

@media (max-width: 500px) {
  .linkforma {
    bottom: 90px;
    right: 20px;
    scale: 0.5;
  }
}
@keyframes rotate-animation {
  0% {
    /* opacity: 1; */
    transform: rotate(8deg);
    /* transform: scale(1); */
  }
  50% {
    /* opacity: 0.7; */
    transform: rotate(0deg);
    /* transform: scale(1.1); */
  }
  100% {
    /* opacity: 1; */
    transform: rotate(-8deg);
    /* transform: scale(1); */
  }
}
