.header__container-links {
  opacity: 1;
  position: relative;
  width: 100%;
  z-index: 10;
  box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}
.header__fixed {
  opacity: 1;
  position: fixed;
  width: 100%;
  z-index: 10;
  box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}
/* menu and logo */
.header__container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 35px;
}
.header__list-social-media {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}
.header__links {
  display: flex;
  justify-content: flex-end;
  transition: 0.4s;
  gap: 10px;
}
.header__links-scroll {
  display: flex;
  justify-content: flex-end;
  padding: 0px;
  transition: 0.4s;
}
.header__menu {
  background: linear-gradient(0.35turn, #ebf8e1, #43b1e0, #ebf8e1, #43b1e0);
  position: relative;
  z-index: 2;
}
.header__menu-padding80 {
  background: linear-gradient(0.35turn, #ebf8e1, #43b1e0, #ebf8e1, #43b1e0);
  position: relative;
  z-index: 2;
  padding-top: 100px;
}
.backdropMenu {
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.hidden {
  display: none;
}
@media (max-width: 800px) {
  .header__menu {
    background: linear-gradient(0.5turn, #62c5c7b3, #ebf8e1);
  }
  .header__menu-padding80 {
    background: linear-gradient(0.5turn, #62c6c7, #ebf8e1);
  }
}
@media (max-width: 450px) {
  .header__list-social-media {
    display: flex;
    justify-content: flex-end;
    gap: 0;
  }
}
