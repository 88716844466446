.main-first {
  padding-bottom: 45px;
  background-image: url(../../../img/fon2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  background-attachment: fixed;
}
.main__important2 {
  padding: 10px;
  border-radius: 20px;
}

@media (max-width: 600px) {
  .main-first {
    padding-bottom: 45px;
    background-image: url(../../../img/fon2mobile.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right bottom;
    background-attachment: fixed;
    /* background-image: none; */
    /* background-color: #53d8ea; */
  }
  .main__important2 {
    margin: 30px;
  }
}
