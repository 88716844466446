.contact {
  background-image: url(../../../img/fon2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  background-attachment: fixed;
  padding-bottom: 35px;
  color: rgb(21, 4, 62);
}

.contacts-title {
  padding: 15px;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.1),
    0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}
.contacts-title h1 {
  text-align: center;
  color: white;
}
.contacts__item {
  padding: 25px 0;
  background-color: azure;
  margin-top: 50px;
  border-radius: 10px;
}
.contacts__item-phone {
  text-align: center;
  margin: 45px auto;
}
.contacts__item-whatsapp {
  text-align: center;
  display: flex;
  gap: 20px;
  max-width: 500px;
  justify-content: center;
  margin: 45px auto;
  align-items: center;
}
.contacts__item-whatsapp img {
  scale: 0.8;
}
.contacts__item-social {
  text-align: center;
}
.contacts__item-social-links {
  max-width: 170px;
  margin: 0 auto;
}
.contacts__item-praca {
  margin: 0px auto;
  text-align: center;
}
.contacts__item-praca h2 {
  margin-bottom: 20px;
}
.contacts__item-praca p {
  padding: 10px;
}
.adress {
  max-width: 125px;
  margin: 0 auto;
}
.contacts__item-praca {
  margin: 35px auto 0 auto;
  padding: 0px 0 25px 0;
  max-width: 600px;
  border-radius: 10px;
}
@media (max-width: 720px) {
  .contacts__item-social-links {
    max-width: 120px;
    margin: 0 auto;
  }
}
@media (max-width: 600px) {
  .contact {
    background-image: url(../../../img/fon2mobile.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right bottom;
    background-attachment: fixed;
    padding-bottom: 35px;
    color: rgb(21, 4, 62);
  }
}
