.logo {
  display: block;
  width: 150px;
}
.logo__img {
  /* transform: scale(1); */
  /* border-radius: 50px 0 50px 0px; */
  /* border: 2px solid white; */
  border-radius: 50%;
}
@media (max-width: 480px) {
  .logo {
    width: 110px;
  }
}
