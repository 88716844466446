.price-phone {
  padding-right: 5px;
  color: black;
}
.price-phone:hover {
  color: #979090;
  background-color: 'red';
}
.header_phone_h3 {
  font-weight: 600;
  font-size: 25px;
  font-weight: 800;
}
.header_phone_link {
  display: flex;
  align-self: center;
  color: #000;
}
.header_phone_link:hover {
  color: #787878;
}
.phone-contact {
  font-weight: 600;
  font-size: 25px;
  font-weight: 800;
}
.phone-contact:hover {
  color: #7f7f7f;
}
.footer__phone-link {
  color: #fff;
  font-size: 25px;
}
.footer__phone-link:hover {
  color: #ccc;
}

@media (max-width: 400px) {
  .header_phone_h3 {
    font-size: 20px;
  }
}
@media (max-width: 350px) {
  .header_phone_h3 {
    font-size: 18px;
  }
}
@media (max-width: 320px) {
  .header_phone_h3 {
    font-size: 14px;
  }
}
